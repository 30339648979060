import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';

type SubscriptionContextValue = {
    isSubmitting: boolean;
    setIsSubmitting: Dispatch<SetStateAction<boolean>>;

    isSubscriptionInProgress: boolean;
    setIsSubscriptionInProgress: Dispatch<SetStateAction<boolean>>;

    planFormValid: boolean;
    setPlanFormValid: Dispatch<SetStateAction<boolean>>;

    billingDetailsFormValid: boolean;
    setBillingDetailsFormValid: Dispatch<SetStateAction<boolean>>;

    isConfirmationOpen: boolean;
    setIsConfirmationOpen: Dispatch<SetStateAction<boolean>>;

    inAuthStep: boolean;
    setInAuthStep: Dispatch<SetStateAction<boolean>>;
};

const SubscriptionContext = createContext<SubscriptionContextValue | undefined>(undefined);

const useSubscriptionContext = (): SubscriptionContextValue => {
    const context = useContext(SubscriptionContext);
    if (!context) {
        throw new Error('useSubscriptionContext must be used within a SubscriptionProvider');
    }

    return context;
};

export { SubscriptionContext, useSubscriptionContext, type SubscriptionContextValue };
