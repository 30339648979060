import { lazy } from 'react';

import type { Team } from '@app/components/ProfileMenu';
import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const NavigationSidebar = lazy(() => import('.'));

class NavigationSidebarWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'show-buttons',
            'user-email',
            'is-provider-alpha',
            'is-limited-view',
            'current-team-id',
            'teams',
            'plan-id',
            'trial-days-left',
            'is-end-of-trial-active',
            'is-team-suspended',
            'show-quick-start-button',
            'show-tasks-button',
            'show-chat-button',
            'project-id',
        ];
    }

    render() {
        const showButtons = this.prop('show-buttons') === 'true';
        const userEmail = this.prop('user-email');
        const isProviderAlpha = this.prop('is-provider-alpha') === 'true';
        const isLimitedView = this.prop('is-limited-view') === 'true';
        const currentTeamId = Number(this.prop('current-team-id'));
        const teams: Team[] = this.jsonProp('teams') ?? [];
        const planId = Number(this.prop('plan-id'));
        const trialDaysLeft = Number(this.prop('trial-days-left'));
        const isEndOfTrialActive = this.prop('is-end-of-trial-active') === 'true';
        const isTeamSuspended = this.prop('is-team-suspended') === 'true';
        const showQuickStartButton = this.prop('show-quick-start-button') === 'true';
        const showTasksButton = this.prop('show-tasks-button') === 'true';
        const showChatButton = this.prop('show-chat-button') === 'true';
        const projectId = this.prop('project-id');

        return (
            <RootProviders>
                <NavigationSidebar
                    showButtons={showButtons}
                    isEndOfTrialActive={isEndOfTrialActive}
                    isTeamSuspended={isTeamSuspended}
                    userEmail={userEmail}
                    isProviderAlpha={isProviderAlpha}
                    isLimitedView={isLimitedView}
                    currentTeamId={Number.isFinite(currentTeamId) ? currentTeamId : -1}
                    teams={teams}
                    planId={planId}
                    trialDaysLeft={Number.isFinite(trialDaysLeft) ? trialDaysLeft : 0}
                    showQuickStartButton={showQuickStartButton}
                    projectId={projectId}
                    showChatButton={showChatButton}
                    showTasksButton={showTasksButton}
                />
            </RootProviders>
        );
    }
}

customElements.define('navigation-sidebar', NavigationSidebarWebComponent);
