import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { FilterObject } from '../../types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const CustomFilterDropdown = lazy(() => import('.'));

class CustomFilterDropdownComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['filter', 'filters'];
    }

    render() {
        const filter = this.jsonProp<FilterObject>('filter') || undefined;
        const filters = this.jsonProp<FilterObject[]>('filters') || [];

        return (
            <RootProviders>
                <CustomFilterDropdown
                    filter={filter}
                    filters={filters}
                    onSelect={(filterObject) => {
                        this.dispatchEvent(new CustomEvent('customfilterselect', { detail: filterObject }));
                    }}
                    onToggleEditor={() => {
                        this.dispatchEvent(new Event('customfiltertoggleeditor'));
                    }}
                    onNewFilter={() => {
                        this.dispatchEvent(new Event('customfilternewfilter'));
                    }}
                    onClear={() => {
                        this.dispatchEvent(new Event('customfilterclear'));
                    }}
                    onCustomize={() => {
                        this.dispatchEvent(new Event('customfiltercustomize'));
                    }}
                    onDelete={(filterObject) => {
                        this.dispatchEvent(new CustomEvent('customfilterdelete', { detail: filterObject }));
                    }}
                />
            </RootProviders>
        );
    }
}

customElements.define('custom-filter-dropdown', CustomFilterDropdownComponent);
