import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { FilterObjectData, FilterOptions } from '../../types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const CustomFilterEditor = lazy(() => import('.'));

class CustomFilterEditorComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['filter', 'options', 'opened'];
    }

    render() {
        const filter = this.jsonProp<FilterObjectData[]>('filter') || [];
        const options = this.jsonProp<FilterOptions[]>('options') || [];
        const opened = this.prop('opened') === 'true';

        return (
            <RootProviders>
                {opened && (
                    <CustomFilterEditor
                        filter={filter}
                        options={options}
                        onApply={(filterObject) => {
                            this.dispatchEvent(new CustomEvent('customfilterapply', { detail: filterObject }));
                        }}
                        onSaveAndApply={(filterObject) => {
                            this.dispatchEvent(new CustomEvent('customfiltersaveapply', { detail: filterObject }));
                        }}
                        onCancel={() => {
                            this.dispatchEvent(new Event('customfiltercancel'));
                        }}
                    />
                )}
            </RootProviders>
        );
    }
}

customElements.define('custom-filter-editor', CustomFilterEditorComponent);
