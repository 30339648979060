import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { TwigContext } from './types';

const PageContainer = lazy(() => import('./components/PageContainer/PageContainer'));
const Reporting = lazy(() => import('./Reporting'));
const RootProviders = lazy(() => import('@app/components/AppProviders'));
const UserPilotTracking = lazy(() => import('./components/UserPilotTracking/UserPilotTracking'));

class ReportingHeaderWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'metabaseTasksUrl',
            'metabaseVolumeUrl',
            'totalTasksCount',
            'totalKeys',
            'totalKeysAddedMoreThanOneDayAgo',
            'totalTasksAddedMoreThanOneDayAgo',
        ];
    }

    render() {
        const metabaseBaseUrl = this.prop('metabaseBaseUrl');
        const metabaseTasksUrl = this.prop('metabaseTasksUrl');
        const metabaseVolumeUrl = this.prop('metabaseVolumeUrl');
        const totalKeys = Number(this.prop('totalKeys'));
        const totalKeysAddedMoreThanOneDayAgo = Number(this.prop('totalKeysAddedMoreThanOneDayAgo'));
        const totalTasksCount = Number(this.prop('totalTasksCount'));
        const totalTasksAddedMoreThanOneDayAgo = Number(this.prop('totalTasksAddedMoreThanOneDayAgo'));

        const twigContext: TwigContext = {
            metabaseBaseUrl,
        };

        return (
            <RootProviders twigContext={twigContext}>
                <PageContainer>
                    <UserPilotTracking />
                    <Reporting
                        totalKeys={totalKeys}
                        totalKeysAddedMoreThanOneDayAgo={totalKeysAddedMoreThanOneDayAgo}
                        totalTasks={totalTasksCount}
                        totalTasksAddedMoreThanOneDayAgo={totalTasksAddedMoreThanOneDayAgo}
                        metabaseTasksUrl={metabaseTasksUrl}
                        metabaseVolumeUrl={metabaseVolumeUrl}
                    />
                </PageContainer>
            </RootProviders>
        );
    }
}

customElements.define('reporting-header', ReportingHeaderWebComponent);
