import type { ProjectsTasksBadges } from '@app/features/project/pages/ProjectsList/types';
import type { Feature } from '@app/hooks/useFeatureState';

import type { FeatureState } from './reducers';
import type { RootState } from './types';

export const getSession = ({ session }: RootState) => session;
export const getSessionUserId = ({ session }: RootState) => session.userId;
export const getSessionProjectId = ({ session }: RootState) => session.projectId;
export const getSessionBranchId = ({ session }: RootState) => session.branchId;
export const getSessionFullName = ({ session }: RootState) => session.fullName;
export const getSessionTrail = ({ session }: RootState) => session.trail;
export const getProjectRights = (state: RootState) => state.project.projectRights;
export const getUserRights = (state: RootState) => state.userRights;
export const getProject = (state: RootState) => state.project;
export const getIsSidebarOpen = (state: RootState) => state.comments.isSidebarOpen;
export const getCommentListStack = (state: RootState) => state.comments.commentListStack;
export const getCommentState = (state: RootState) => state.comments;
export const getBranch = (state: RootState) => state.branch;
export const getTeam = (state: RootState) => state.team;
export const getTeamId = (state: RootState) => state.team.id;
export const getTranslationsInEditMode = (state: RootState) => state.translationEditor.translationsInEditMode;
export const isLockedTranslationsLoaded = (state: RootState) => state.translationEditor.lockedTranslationsLoaded;
export const getFeatures = (state: RootState) => state.features;

export const getFeatureState = (features: FeatureState, name: Feature) => features[name];
export const getIsFeatureEnabled = (features: FeatureState, name: Feature) => {
    const featureState = features[name];

    return featureState !== null && featureState !== undefined && [true, 'on', 'true'].includes(featureState);
};

export const getTaskCountFromTasks = (tasks?: ProjectsTasksBadges, projectId?: string, branchId?: string) => {
    if (!tasks || !projectId || typeof tasks[projectId] === 'undefined') {
        return 0;
    }

    if (branchId) {
        const branch = tasks[projectId].find((item) => item.insert_id === Number(branchId));

        return branch ? branch.task_count : 0;
    }

    return tasks[projectId][0].task_count;
};
