import type { PermissionId } from '@app/api/team';

import type { Language } from './language';

export interface Filename {
    format: number;
    name: string;
    id: string;
    stats: {
        wordCount: number;
        wordCountUntranslated: number;
        calcIsFuzzy: number;
        percentDone: number;
        languages: {
            [id: string]: {
                percentDone: number;
                wordCount: number;
                wordCountUntranslated: number;
            };
        };
    };
    title: string | null;
}

export interface AdminRights {
    upload: boolean;
    download: boolean;
    tasks: boolean;
    contributors: boolean;
    screenshots: boolean;
    manageKeys: boolean;
    manageLanguages: boolean;
    settings: boolean;
    activity: boolean;
    statistics: boolean;
    createBranches: boolean;
    mergeBranches: boolean;
}

export enum ProjectType {
    localizationFiles = '0',
    pagedDocuments = '1',
    contentIntegration = '2',
}

export type BuiltinFilters =
    | 'builtin_4'
    | 'builtin_6'
    | 'builtin_20'
    | 'builtin_21'
    | 'builtin_22'
    | 'builtin_23'
    | 'builtin_24'
    | 'builtin_25'
    | 'builtin_26'
    | 'builtin_27'
    | 'builtin_28'
    | 'builtin_29'
    | 'builtin_30'
    | 'builtin_31'
    | 'builtin_32'
    | 'builtin_33'
    | 'builtin_34';

export type ProjectDisplayQaIssues = {
    qa_not_proofread: number;
    calc_is_fuzzy: number;
    calc_qa_spelling_and_grammar: number;
    calc_qa_mismatch_placeholder: number;
    calc_qa_mismatch_html: number;
    calc_qa_mismatch_url_count: number;
    calc_qa_mismatch_url: number;
    calc_qa_mismatch_leading_whitespace: number;
    calc_qa_mismatch_trailing_whitespace: number;
    calc_qa_mismatch_email_count: number;
    calc_qa_mismatch_email: number;
    calc_qa_mismatch_bracket: number;
    calc_qa_mismatch_number: number;
    calc_qa_double_space: number;
    calc_qa_special_placeholder: number;
    calc_qa_unbalanced_brackets: number;
};

export interface Project {
    id: string;
    name: string;
    type: ProjectType;
    description: string;
    langs: Language[];
    filenames: Filename[];
    availableRefLang: string | null;
    projectContributors: string | null; // TODO: Should be a number, but the value that comes from the server is a string.
    allKeysCount: string | null; // TODO: Should be a number, but the value that comes from the server is a string.
    mau: number | null;
    isBranchingEnabled: boolean;
    offlineTranslation: boolean;
    isOtaEnabled?: boolean;
    branches: {
        currentId: string;
        currentName: string;
        list: string[];
    } | null;
    isAdmin: boolean;
    isCollapsed: boolean;
    adminRights: AdminRights | null;
    permissions: Record<PermissionId, boolean> | null;
    stats: {
        percentTranslatedTotal: number;
        baseWordCount: number;
        qaTotalCount: number;
        display: Partial<ProjectDisplayQaIssues>;
    } | null;
    statsLoaded?: boolean;
}
