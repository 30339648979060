import { lazy } from 'react';

import type { TaskItemStatus } from '@app/api/editor';
import { ReactWebComponent } from '@app/utils/web-components';

import { type TranslationDTO, convertMismatchDataDTOtoModel } from '../../../../types/translation';
import type Translation from '../../../../types/translation';
import { completeTaskItemSuccess, handleTranslationUpdated } from '../../utils/completeTaskItemSuccess';
import type { CustomTranslationStatusItem } from './components/CustomTranslationStatuses';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const TranslationActions = lazy(() => import('.'));

class TranslationActionsComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'langId',
            'referenceLanguageId',
            'canIgnoreQATest',
            'translation',
            'isHtmlMismatchErrorsVisible',
            'isPlaceholdersMismatchErrorsVisible',
            'showReviewButton',
            'reviewed',
            'customTranslationStatuses',
            'enableRequestCorrections',
            'showGengoCommentsButton',
            'currentLockTitle',
        ];
    }

    render() {
        const handleQATestIgnored = (test: { testNumber: number }) => {
            this.dispatchEvent(new CustomEvent('onqatestignored', { detail: test.testNumber, bubbles: true }));
        };

        const handleMismatchErrorsIgnored = () => {
            this.dispatchEvent(new Event('onmismatcherrorsignored', { bubbles: true }));
        };

        const handleHtmlPlaceholdersVisibilityChanged = (visible: boolean) => {
            if (visible) {
                this.dispatchEvent(new Event('onmismatchpopovershow', { bubbles: true }));
            }
        };

        const langId = this.jsonProp<number>('langId');
        const referenceLanguageId = this.jsonProp<number>('referenceLanguageId');
        const canIgnoreQATest = this.prop('canIgnoreQATest') === 'true';
        const isHtmlMismatchErrorsVisible = this.prop('isHtmlMismatchErrorsVisible') === 'true';
        const isPlaceholdersMismatchErrorsVisible = this.prop('isPlaceholdersMismatchErrorsVisible') === 'true';
        const lockTitle = this.prop('currentLockTitle');

        /*
            Twig js has some limitations while rendering twig templates, so we need to handle escaping from here
            This is a temporary solution (I hope you are not reading this some years later).
         */
        const translationProp = this.prop('translation');
        let translationDto: TranslationDTO | null = null;
        try {
            translationDto = JSON.parse(translationProp);
        } catch (e) {
            const element = document.createElement('div');
            element.innerHTML = translationProp;
            try {
                JSON.parse(element.innerText);
                this.setAttribute('translation', element.innerText);
            } catch {
                return null;
            }
        }

        if (!translationDto) {
            return null;
        }

        const translation: Omit<Translation, 'value' | 'sourceValue' | 'reviewed' | 'isBaseLanguage'> = {
            translationId: parseInt(translationDto.id, 10),
            keyId: parseInt(translationDto.akey_id, 10),
            projectId: translationDto.project_id,
            isPlural: translationDto.is_plural === '1',
            qaResults: translationDto.qa_results,
            mismatchResults: convertMismatchDataDTOtoModel(translationDto.p_mismatch_data),
            activeTask: {
                taskId: parseInt(translationDto.active_task?.task_id, 10),
                taskItemId: parseInt(translationDto.active_task?.task_item_id, 10),
                taskItemStatus: translationDto.active_task?.task_item_status as TaskItemStatus,
            },
            segmentNumber: parseInt(translationDto.segment_number, 10),
            translationVerified: translationDto.fuzzy === '0',
        };

        const {
            keyId,
            isPlural,
            qaResults,

            mismatchResults,
            segmentNumber,
            translationId,
            activeTask: { taskId, taskItemId, taskItemStatus },
            translationVerified,
        } = translation;

        const showReviewButton = !!this.jsonProp<boolean>('showReviewButton');
        const reviewed = !!this.jsonProp<boolean>('reviewed');
        const enableRequestCorrections = Boolean(this.jsonProp<boolean>('enableRequestCorrections'));
        const showGengoCommentsButton = Boolean(this.jsonProp<boolean>('showGengoCommentsButton'));

        const customTranslationStatuses =
            this.jsonProp<{
                enabled: string;
                multipleSelection: string;
                availableStatuses: CustomTranslationStatusItem[];
                enabledStatusIds: number[];
                activeStatusIds: number[];
            }>('customTranslationStatuses') ?? null;

        if (langId === null || keyId === null || translationId === null) {
            return null;
        }

        return (
            <AppProviders>
                <TranslationActions
                    languageId={langId}
                    keyId={keyId}
                    taskId={taskId || 0}
                    taskItemId={taskItemId || 0}
                    referenceLanguageId={referenceLanguageId || 0}
                    translationId={translationId}
                    taskItemStatus={taskItemStatus}
                    onTranslationUpdated={handleTranslationUpdated}
                    onCompleteTaskSuccess={completeTaskItemSuccess}
                    segmentNumber={segmentNumber || 0}
                    qaResults={qaResults}
                    canIgnoreQATest={canIgnoreQATest}
                    onQATestIgnored={handleQATestIgnored}
                    mismatchResults={mismatchResults}
                    isHtmlMismatchErrorsVisible={isHtmlMismatchErrorsVisible}
                    isPlaceholdersMismatchErrorsVisible={isPlaceholdersMismatchErrorsVisible}
                    onMismatchErrorsIgnored={handleMismatchErrorsIgnored}
                    onHtmlPlaceholdersVisibilityChanged={handleHtmlPlaceholdersVisibilityChanged}
                    isPlural={isPlural}
                    showReviewButton={showReviewButton || false}
                    reviewed={reviewed || false}
                    translationVerified={translationVerified}
                    customTranslationStatuses={
                        customTranslationStatuses && {
                            ...customTranslationStatuses,
                            enabled: customTranslationStatuses.enabled === 'true',
                            multipleSelection: customTranslationStatuses.multipleSelection === 'true',
                        }
                    }
                    enableRequestCorrections={enableRequestCorrections}
                    showGengoCommentsButton={showGengoCommentsButton}
                    lockTitle={lockTitle}
                />
            </AppProviders>
        );
    }
}

customElements.define('editor-actions', TranslationActionsComponent);
