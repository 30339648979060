import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const OrdersPageFooter = lazy(() => import('./OrdersPageFooter'));

class OrdersPageFooterWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <OrdersPageFooter />
            </RootProviders>
        );
    }
}

customElements.define('orders-page-footer', OrdersPageFooterWebComponent);
