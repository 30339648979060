/* eslint-disable max-classes-per-file */
import debounce from 'debounce';
import type { ReactNode } from 'react';
import * as React from 'react';
import { StrictMode, Suspense } from 'react';
import { type Root, createRoot } from 'react-dom/client';

import bugsnag from '@app/utils/bugsnag';

/*
 * __webpack_public_path__ variable stores the path to the assets generated by Webpack.
 * This variable is used to override "publicPath" in webpack.config.js. It can be overridden by placing
 * __webpack_public_path__ in an entry file. Currently, __webpack_public_path__ is overridden in
 * ReactWebComponent.tsx that is common module for all entry files in the application. Idea comes from:
 * https://stackoverflow.com/questions/40661251/how-to-use-webpack-public-path-variable-in-a-webpack-configuration
 * */
if (window.env?.cdnFrontendAssetsUrl) {
    __webpack_public_path__ = `${window.env.cdnFrontendAssetsUrl}/js/compiled/ui/`;
}

bugsnag.start(React);

/*
 * Bugsnag `reactPlugin` is necessary to report errors to Bugsnag that happens inside React. It will be
 * available only in production so thereof there are these two approaches of rendering the component.
 * */
const BugsnagErrorBoundary = bugsnag.getReactPlugin()?.createErrorBoundary();

class PropError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PropError';
    }
}

/*
 * ReactWebComponent is a convenience class created to make it easier to create a React web components.
 * We use these web components as compatibility layer between Lokalise UI and legacy code. Web components
 * should be used in Twig/jQuery and in legacy React instead of importing react components directly.
 * The class is abstraction on top of an open Web standard called "Web Components": https://developer.mozilla.org/en-US/docs/Web/Web_Components
 * */
export default class ReactWebComponent extends HTMLElement {
    /*
     * Debounce `updateHtml` method to improve performance. The method will be run only once. This is especially helpful
     * if the Web component is used inside legacy React context, because there the attributes are passed one by one
     * which might cause a rerender for each attribute. This solves that case as the component is rendered only when all
     * attributes are present.
     * */
    private readonly debounceRender;

    private root: Root | null = null;

    constructor() {
        super();
        this.debounceRender = debounce(() => this.updateHtml(), 0);
    }

    public connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }

        this.debounceRender();
    }

    public disconnectedCallback() {
        if (this.root) {
            this.debounceRender.clear();
            this.root.unmount();
            this.root = null;
        }
    }

    public attributeChangedCallback() {
        this.debounceRender();
    }

    private updateHtml() {
        if (!this.isConnected || !this.root) {
            return;
        }

        /*
         * This is a clever way to fall back to current html while loading lazy loaded components. That way
         * we don't have to maintain separate placeholder in React if we don't want to.
         * This still can be overwritten by children web-components with custom placeholder while loading.
         * */
        const fallback = <div dangerouslySetInnerHTML={{ __html: this.innerHTML }} />;

        if (BugsnagErrorBoundary) {
            this.root.render(
                <BugsnagErrorBoundary>
                    <StrictMode>
                        <Suspense fallback={fallback}>{this.render()}</Suspense>
                    </StrictMode>
                </BugsnagErrorBoundary>,
            );
        } else {
            this.root.render(
                <StrictMode>
                    <Suspense fallback={fallback}>{this.render()}</Suspense>
                </StrictMode>,
            );
        }
    }

    /*
     * The main idea of this method is to throw an error in case an attribute is not defined. That ensures that the type
     * of the value will always be `string`. This method is a wrapper of `getAttribute` method, which is the native way
     * of retrieving web component attribute values. `getAttribute` returns either `string` or a `null` if the attribute
     * is not defined. In most cases React props don't accept `null` value so in those cases it would be needed to check
     * whether the value is `null` before rendering the component. That leads to some boilerplate code. Getting rid of
     * the boilerplate is the reason why this method exists.
     * */
    protected prop(prop: string): string {
        const value = this.getAttribute(prop);

        /*
         * Checking if attribute is specified on element (`null` means that attribute is not specified on element).
         * */
        if (value === null) {
            throw new PropError(`Missing required attribute: '${prop}'`);
        }

        return value;
    }

    // A helper method to make it easier to work with pros that has JSON value.
    protected jsonProp<T>(prop: string): T | null {
        const value = this.prop(prop);

        // Empty string is not a valid JSON value.
        if (value === '') {
            return null;
        }

        return JSON.parse(value);
    }

    protected render(): ReactNode {
        throw new Error('You should implement this method in a subclass');
    }
}
