import type { ButtonProps } from '@lokalise/louis';
import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import prepareOptions from './prepareOptions';
import type { Label, ProjectFilterData } from './types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const Dropdown = lazy(() => import('./AdvancedFilterDropdown'));

declare global {
    interface HTMLElementTagNameMap {
        'advanced-filter-dropdown': AdvancedFilterDropdownWebComponent;
    }
}

type EventMap = {
    onChange: CustomEvent<string>;
    onRemove: CustomEvent<string>;
    onReset: Event;
};

export interface AdvancedFilterDropdownWebComponent extends HTMLElement {
    addEventListener<K extends keyof EventMap>(
        type: K,
        listener: (this: HTMLElement, ev: EventMap[K]) => unknown,
        options?: boolean | AddEventListenerOptions,
    ): void;
    removeEventListener<K extends keyof EventMap>(
        type: K,
        listener: (this: HTMLElement, ev: EventMap[K]) => unknown,
        options?: boolean | EventListenerOptions,
    ): void;
}

export class AdvancedFilterDropdownWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['value', 'disabled', 'data', 'label'];
    }

    attributeChangedCallback(...args: unknown[]) {
        super.attributeChangedCallback();
        const [name, oldValue, newValue] = args;
        if (name === 'value' && oldValue !== newValue) {
            this.dispatchEvent(new CustomEvent('onChange', { detail: newValue || null }));
        }
    }

    render() {
        const value = this.prop('value');
        const variant = this.prop('variant') as ButtonProps['variant'];
        const data = this.jsonProp<ProjectFilterData>('data') ?? window.project_filter_data;
        const label = this.jsonProp<Label>('label') ?? { normalText: 'Filter' };
        const options = prepareOptions(
            value,
            window.preloadedState.project.documentOrContentProject,
            data,
            window.current_view,
        );

        return (
            <RootProviders>
                <Dropdown
                    value={value}
                    disabled={this.prop('disabled') === 'true'}
                    options={options}
                    label={label}
                    variant={variant}
                    onChange={(val) => {
                        this.dispatchEvent(new CustomEvent('onChange', { detail: val }));
                    }}
                    onRemove={(val) => this.dispatchEvent(new CustomEvent('onRemove', { detail: val }))}
                />
            </RootProviders>
        );
    }
}

customElements.define('advanced-filter-dropdown', AdvancedFilterDropdownWebComponent);
